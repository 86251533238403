<template>
  <div>
    <div v-if="!isEmpty(selectedTrip)">
      <ReservationBook
        :selected-trip-props="selectedTrip"
        :is-combination="false"
      />
    </div>
    <div v-else-if="combinationSelectedTrip">
      <ReservationBook
        :selected-trip-props="combinationSelectedTrip.journeys"
        :is-combination="true"
      />
    </div>
    <div v-else>
      <b-alert
        show
        variant="danger"
        class="px-2 py-1"
      >
        Không có chuyến bay nào được chọn. Vui lòng chọn lại
      </b-alert>
    </div>
  </div>
</template>
<script>
import { computed, onUnmounted, onBeforeUnmount } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import { BAlert } from 'bootstrap-vue'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'

import storeModule from '@flightv2/bookingStoreModule'
import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BAlert,
    ReservationBook: () => import('./ReservationBook.vue'),
  },
  setup() {
    const {
      FLIGHT_APP_STORE_MODULE_NAME,
    } = useBookingHandle()

    if (!store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
      store.registerModule(FLIGHT_APP_STORE_MODULE_NAME, storeModule)
    }

    onUnmounted(() => {
      if (store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(FLIGHT_APP_STORE_MODULE_NAME)
      }
    })

    const { router } = useRouter()

    const selectedTrip = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSelectedTrip`])
    const combinationSelectedTrip = computed(() => store.getters['app-flight-v2/getCombinationSelectedTrip'])

    const setReload = setTimeout(() => {
      if (isEmpty(selectedTrip.value.flat()) && !combinationSelectedTrip.value) {
        router.replace({ name: 'apps-flightsV2-result' })
      }
    }, 1000)
    onBeforeUnmount(() => {
      clearTimeout(setReload)
    })

    return {
      selectedTrip, isEmpty, combinationSelectedTrip,
    }
  },
}
</script>
<style lang="">

</style>
